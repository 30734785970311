<template>
  <v-container
    id="workloads"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <base-material-card color="grey">
          <template v-slot:heading>
            <div class="text-h3 ">
              {{ $t('submit-workload-title') }}: <strong>{{ date }}</strong>
            </div>
            <div class="text-h4 ">
              {{ $t('time-remaining') }}: {{ remainingTime }}
            </div>

            <div class="text-subtitle-1 font-weight-light">
              {{ $t('workload-instruction') }}
            </div>
          </template>

          <base-material-alert
            v-if="!initializing && hasWorkloadForCurrentDay"
            color="info"
            icon="mdi-bell"
            class="text-h4"
          >
            <div v-html="$t('workload-already-submitted', { date: date })"></div>
          </base-material-alert>

          <v-form v-if="!initializing">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="hoursWorked"
                    :label="$t('label-hours-worked')"
                    type="number"
                    :disabled="initializing"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="hoursOnStandby"
                    :label="$t('label-hours-on-standby')"
                    type="number"
                    :disabled="initializing"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="drivenDistance"
                    :label="$t('label-driven-distance')"
                    type="number"
                    :disabled="initializing"
                  />
                </v-col>
              </v-row>
              <base-material-alert
                v-if="errorMessage"
                color="error"
              >
                {{ errorMessage }}
              </base-material-alert>
              <v-row>
                <v-col>
                  <v-dialog
                    v-model="dialog"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        v-bind="attrs"
                        :disabled="initializing"
                        v-on="on"
                      >
                        {{ $t('submit') }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        {{ $t('Warning') }}!
                      </v-card-title>

                      <v-card-text>
                        {{ $t('workload-additional-instruction') }}
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="red"
                          text
                          @click="dialog = false"
                        >
                          {{ $t('Cancel') }}
                        </v-btn>
                        <v-btn
                          color="green"
                          @click="submitWorkload"
                        >
                          {{ $t('label-submit-my-workload') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <base-material-card
          icon="mdi-clipboard-text"
          :title="$t('last-week-workload')"
          class="px-5 py-3"
        >
          <v-card
            v-if="initializing"
            color="grey"
            dark
          >
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card>

          <base-material-alert
            v-if="!initializing && lastWeekWorkloads.length === 0"
            color="info"
          >
            You have no workloads reported for last week.
          </base-material-alert>

          <v-simple-table
            v-if="!initializing && lastWeekWorkloads.length > 0"
          >
            <thead>
              <tr>
                <th class="primary--text">
                  {{ $t('Date') }}
                </th>
                <th class="primary--text">
                  {{ $t('label-hours-worked') }}
                </th>
                <th class="primary--text">
                  {{ $t('label-hours-on-standby') }}
                </th>
                <th class="primary--text">
                  {{ $t('label-driven-distance') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="workload of lastWeekWorkloads"
                :key="workload.day"
              >
                <td>{{ workload.day }}</td>
                <td>{{ workload.hours_worked }} h</td>
                <td>{{ workload.hours_on_standby }} h</td>
                <td>{{ workload.driven_distance }} km</td>
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import axios from 'axios'

  export default {
    name: 'SubmitWorkload',
    data () {
      return {
        lastMomentType: undefined,
        hoursWorked: undefined,
        hoursOnStandby: undefined,
        drivenDistance: undefined,
        counter: 0,
        dialog: false,
        intervalId: undefined,
        date: '',
        remainingTime: '',
        initializing: true,
        lastWeekWorkloads: [],
        hasWorkloadForCurrentDay: false,
        errorMessage: '',
      }
    },
    computed: {
      configTimeLimit () {
        return '10:00:00'
      },
    },
    created () {
      // console.log('created')
    },
    async activated () {
      await this.loadLastWeekWorkload()
    },
    deactivated () {
      clearInterval(this.intervalId)
    },
    async mounted () {
      this.updateTime()
      this.intervalId = setInterval(
        () => this.updateTime(),
        1000,
      )
      await this.loadLastWeekWorkload()
      this.initializing = false
    },
    destroyed () {
      clearInterval(this.intervalId)
    },
    methods: {
      async loadLastWeekWorkload () {
        await this.$store.dispatch('enableLoading')

        this.clearForm()

        try {
          const response = await axios.get(
            this.$store.getters.getApiHost + '/parcel/v1/couriers/daily-workloads/last-week',
            {
              headers: {
                Authorization: 'Bearer ' + this.$store.getters['auth/getAccessToken'],
              },
            },
          )

          this.lastWeekWorkloads = response.data
          this.hasWorkloadForCurrentDay = !!this.lastWeekWorkloads.find(workload => workload.day === this.date)

          if (this.hasWorkloadForCurrentDay) {
            const currentWorkload = this.lastWeekWorkloads.find(workload => workload.day === this.date)
            this.hoursWorked = currentWorkload.hours_worked
            this.hoursOnStandby = currentWorkload.hours_on_standby
            this.drivenDistance = currentWorkload.driven_distance
          }
        } catch (e) {
          // no error handler at this phase
        }
        await this.$store.dispatch('disableLoading')
      },
      clearForm () {
        this.hoursWorked = ''
        this.hoursOnStandby = ''
        this.drivenDistance = ''
      },
      async submitWorkload () {
        this.dialog = false
        await this.$store.dispatch('enableLoading')

        try {
          await axios.post(
            this.$store.getters.getApiHost + '/parcel/v1/couriers/daily-workloads',
            {
              day: this.date,
              hours_worked: parseFloat(this.hoursWorked),
              hours_on_standby: parseFloat(this.hoursOnStandby),
              driven_distance: parseFloat(this.drivenDistance),
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.$store.getters['auth/getAccessToken'],
              },
            },
          )

          await this.loadLastWeekWorkload()
        } catch (error) {
          this.errorMessage = error?.response?.data?.message
          setTimeout(
            () => {
              this.errorMessage = ''
            },
            2000,
          )
        }

        await this.$store.dispatch('disableLoading')
      },
      updateTime () {
        const now = moment()
        let borderTime = moment(this.configTimeLimit, 'HH:mm:ss')
        let newMomentType

        if (borderTime - now > 0) {
          this.date = moment().subtract(1, 'days').format('YYYY-MM-DD')
          newMomentType = -1
        } else {
          borderTime = moment(this.configTimeLimit, 'HH:mm:ss').add(1, 'days')
          this.date = now.format('YYYY-MM-DD')
          newMomentType = 1
        }

        if (newMomentType !== this.lastMomentType) {
          this.loadLastWeekWorkload()
        }
        this.lastMomentType = newMomentType

        const hoursLeft = borderTime.diff(now, 'hours')
        const minutesLeft = borderTime.diff(now, 'minutes') % 60
        const secondsLeft = borderTime.diff(now, 'seconds') % 60

        this.remainingTime = `${hoursLeft}h ${minutesLeft}m ${secondsLeft}s`
      },
    },
  }
</script>

<style scoped>

</style>
